import React from "react"
import { Helmet } from "react-helmet"

// import CallNow from "../components/callNow"
import Layout from "../components/layout.js"
import "../styles/opony.scss"

const AboutPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Opony Tychy - najlepsze marki opon w Wulkanizacji w Tychach
        </title>

        <link rel="canonical" href="https://oponytychy.pl/opony-tychy" />
        <meta
          name="description"
          content="Sprzedajemy opony letnie, opony zimowe, opony wielosezonowe  firm: Michelin, GoodYear, Continental, Barum, Dębica, Bfgoodrich, Uniroyal, Bridgestone, Dayton, Firestone, Dunlop, Kormoran, Sava, Kleber."
        />
        <meta
          name="keywords"
          content="serwis ogumienia, serwis ogumienia Tychy, wulkanizator, wulkanizator Tychy"
        />
      </Helmet>

      {/* <CallNow />*/}
      <div className="main">
        <div className="container_12">
          <div className="grid_4">
            <div className="box1">
              <div className="indents5">
                <h2 className="p3-1">
                  <span>
                    Opony i <strong>felgi</strong>
                  </span>
                </h2>
                <p className="p2">
                  Dzięki współpracy z hurtowniami oponiarskimi jesteśmy wstanie
                  zaproponować Państwu szeroki wachlarz{" "}
                  <strong>opon letnich</strong> i <strong>opon zimowych</strong>{" "}
                  oraz <strong>felg </strong>
                  uznanych światowych producentów
                </p>
                <br />
                <p className="p2">O szczegóły pytaj w serwisie. </p>
              </div>
            </div>
          </div>

          <div className="grid_8 tyres">
            <div className="box1">
              <div className="indents5">
                <h2 className="p3-1">
                  <span>
                    Sprzedajemy <strong>opony:</strong>
                  </span>
                </h2>
                <table>
                  <thead>
                    <tr>
                      <th className="column-1 row__header">&nbsp;</th>
                      <th className="column-2 row__header">Klasa Premium </th>
                      <th className="column-3 row__header">Klasa Średnia</th>
                      <th className="column-4 row__header">Klasa Budżetowa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="column-1 company__group">
                        Grupa Michelin
                      </td>
                      <td className="column-2 company__cell">Michelin</td>
                      <td className="column-3 company__cell">Kleber</td>
                      <td className="column-4 company__cell">Kormoran</td>
                    </tr>
                    <tr>
                      <td className="column-1 company__group">
                        Grupa Continental
                      </td>
                      <td className="column-2 company__cell">Continental</td>
                      <td className="column-3 company__cell">Uniroyal</td>
                      <td className="column-4 company__cell">Barum</td>
                    </tr>
                    <tr>
                      <td className="column-1 company__group">
                        Grupa Goodyear
                      </td>
                      <td className="column-2 company__cell">Goodyear</td>
                      <td className="column-3 company__cell">Fulda</td>
                      <td className="column-4 company__cell">Dębica</td>
                    </tr>
                    <tr>
                      <td className="column-1 company__group">Grupa Dunlop</td>
                      <td className="column-2 company__cell">Dunlop</td>
                      <td className="column-3 company__cell">Bfgoodrich</td>
                      <td className="column-4 company__cell">Sava</td>
                    </tr>
                    <tr>
                      <td className="column-1 company__group">
                        Grupa Bridgestone
                      </td>
                      <td className="column-2 company__cell">Bridgestone</td>
                      <td className="column-3 company__cell">Firestone</td>
                      <td className="column-4 company__cell">Dayton</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
